<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/xwzx.png" alt="" />
      <div class="jsfnB1">通知公告</div>
      <div class="jsfnC1">ANNOUNCEMENTS</div>
    </div>
    <div class="body">
      <div class="third">
        <div class="xwrb">最新通告</div>
        <div class="sRight">
          <div class="solution">HOT LIST</div>
          <div class="more">
            <!-- <div class="gdal">更多通知</div> -->
            <!-- <div>></div> -->
          </div>
        </div>
        <div
          class="thirdContent"
          v-for="(item, index) in list"
          :key="index"
          :class="index === third.length - 1 ? 'thirdBorder' : ''"
        >
          <div><img class="leftImg" :src="baseURL+item.fileSrc" alt="" /></div>
          <div class="rightCon">
            <div class="title">{{ item.title }}</div>
            <div class="tCon">{{ item.introduce }}</div>
            <div class="date">{{ item.sendTime }}</div>
            <div class="dateMore" @click="goMore(item)">查看详情></div>
          </div>
        </div>
      </div>
      <div class="block">
<!--        <el-pagination-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page.sync="currentPage2"-->
<!--          :page-sizes="[8, 16, 24, 32]"-->
<!--          :page-size="100"-->
<!--          layout="sizes, prev, pager, next"-->
<!--          :total="32"-->
<!--        >-->
<!--        </el-pagination>-->
        <!--        </el-pagination>-->
        <pagination
          style="margin-left: 15px"
          v-show="total>0"
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          @pagination="getList"
        />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getNewsNoticePageListByType} from "../../api/open";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      third: [
        {
          img: require("@/assets/tone.png"),
          title: "公司放假通知",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/ttwo.png"),
          title: "年前最新消息",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tthree.png"),
          title: "春节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tone.png"),
          title: "公司项目中标成功",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/ttwo.png"),
          title: "调整通知",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tthree.png"),
          title: "软件工程完结",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tone.png"),
          title: "春节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/ttwo.png"),
          title: "公司项目中标成功",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
      ],
      list:[],
      pageNum:1,
      pageSize: 8,
      total:0,
      baseURL: process.env.VUE_APP_BASE_API,
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      getNewsNoticePageListByType({pageNum:this.pageNum,pageSize: this.pageSize,types:["2","3"]}).then(res=>{
        this.total = res.total
        this.list = res.rows
      })
    },
    goMore(row) {
      this.$router.push({path:"pressCenter2",query:{id:row.id} });
    }
  },
};
</script>

<style scoped>
.xwrb {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.sRight {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.solution {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.thirdContent {
  display: flex;
  margin: 16px 32px 0 32px;
  border-bottom: 1px solid #d8d8d8;
}
.thirdBorder {
  border: none;
}
.leftImg {
  margin: 0 24px 16px 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rightCon {
  width: 100%;
  position: relative;
}
.title {
  font-size: 24px;
  margin-bottom: 16px;
  letter-spacing: 1px;
}
.tCon {
  margin-right: 16px;
  color: #595959;
  letter-spacing: 1px;
}
.date {
  color: #595959;
  position: absolute;
  bottom: 16px;
  letter-spacing: 1px;
}
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.block {
    margin: 40px 32px 0 0;
    display: flex;
    justify-content: end;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
.dateMore {
  color: #595959;
  position: absolute;
  bottom: 16px;
  right: 24px;
  letter-spacing: 1px;
  cursor: pointer;
}
</style>
